import BottomNav from 'components/BottomNav';
export default {
  components: {
    BottomNav
  },
  data: () => ({
    customer: {}
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    myinfo() {
      let url = '/customer/main/myinfo';
      this.$axios.post(url).then(resp => {
        this.customer = resp.data.data;
      });
    }
  },
  mounted: function () {
    this.myinfo();
  }
};