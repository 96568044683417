import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import BottomNav from '../components/BottomNav';
import { showToast } from "vant";
import VueClipboard from "vue-clipboard3";
const {
  toClipboard
} = VueClipboard();
export default {
  name: "ProductList",
  components: {
    BottomNav
  },
  data() {
    return {
      products: [],
      source: null
    };
  },
  methods: {
    initProducts() {
      let url = '/customer/platflag-list?platFlag=h5';
      this.$axios.post(url).then(resp => {
        this.products = resp.data.list.filter(item => {
          return item.id != 23;
        });
      });
    },
    copyLink() {
      this.copy('https://m.52haoma.com/ad-web/proxy/' + this.source);
      showToast("推广链接复制成功");
    },
    copy(Msg) {
      try {
        //复制
        toClipboard(Msg);

        //下面可以设置复制成功的提示框等操作
        //...
      } catch (e) {
        //复制失败
        console.error(e);
      }
    }
  },
  mounted() {
    this.initProducts();
    this.source = sessionStorage.getItem("account");
  }
};