import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "aditem"
};
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_BottomNav = _resolveComponent("BottomNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    "left-arrow": "",
    "left-text": "返回",
    style: {
      "width": "7.5rem",
      "margin": "0 auto"
    },
    title: "我的推广链接",
    onClickLeft: _cache[0] || (_cache[0] = $event => $options.goBack())
  }), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "24"
    }, {
      default: _withCtx(() => [_createElementVNode("p", _hoisted_2, [_createElementVNode("a", {
        href: $data.link
      }, "特惠套餐推广链接", 8, _hoisted_3), _createVNode(_component_van_button, {
        type: "primary",
        size: "small",
        onClick: $options.copyTelcomStar59
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("复制")])),
        _: 1
      }, 8, ["onClick"])]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        style: {
          "font-size": "0.2rem"
        }
      }, "此链接为您的专属链接，可以把它直接发给您的客户，通过该链接下单的用户发展人会归属到您名下", -1))]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_BottomNav, {
    index: 3
  })]);
}