import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import axios from 'axios'
import VueWechatTitle from "vue-wechat-title";
import 'vant/lib/index.css';
import {showDialog } from "vant";



axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.status == "1004"){
    showDialog ({message: "会话超时，请重新登录或者打开首页链接" });
    location.href = '/login';
  }else if(response.data.status == "0000"){
    return response;
  }else{
    showDialog ({message: response.data.message});
    // 对响应错误做点什么
    return Promise.reject(response);
  }
}, function (error) {
  showDialog ({message: "网络不稳定，请稍后重试" });
  // 对响应错误做点什么
  return Promise.reject(error);
});





const app = createApp(App)
app.use(router)
app.use(VueWechatTitle)



app.mount('#app')
// 定义全局变量
app.config.globalProperties.$axios = axios;
// 服务端返回的执行成功的状态码
app.config.globalProperties.SUCCESS = '0000';



