import "core-js/modules/es.array.push.js";
import { Toast } from 'vant';
import BottomNav from 'components/BottomNav';
export default {
  components: {
    BottomNav
  },
  data: () => ({
    coupon: {
      status: 0
    },
    coupons: [],
    finished: false,
    loading: false,
    pageNum: 1,
    result: null
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    mycoupon() {
      let url = '/customer/main/mycoupon';
      this.coupon.pageNum = this.pageNum;
      this.loading = true;
      this.$axios.post(url, this.coupon).then(resp => {
        if (resp.data.status == this.STATUS_SUCCESS) {
          let list = resp.data.data.list;
          if (list.length == 0) {
            if (this.pageNum == 1) {
              this.result = "您没有优惠券";
            } else {
              this.result = "没有更多了";
            }
            this.finished = true;
          } else {
            for (let i = 0; i < list.length; i++) {
              this.coupon.push(list[i]);
            }
            if (list.length < 40) {
              this.result = "没有更多了";
              this.finished = true;
            } else {
              this.pageNum++;
            }
          }
        } else {
          Toast(resp.data.message);
          this.finished = true;
        }
        this.loading = false;
      });
    },
    chgStatus() {
      // 清空原数据
      this.coupons = [];
      this.pageNum = 1;
      this.mycoupon();
    }
  },
  mounted: function () {
    this.mycoupon();
  }
};