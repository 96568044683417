import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from './assets/gaba.png';
const _hoisted_1 = {
  key: 0,
  class: "footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", null, [_cache[2] || (_cache[2] = _createElementVNode("div", {
    id: "app"
  }, null, -1)), _createVNode(_component_router_view), _createElementVNode("div", {
    class: "goTop",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.backtop && $options.backtop(...args))
  }, [_createVNode(_component_van_icon, {
    name: "back-top"
  })]), _ctx.$route.path == '/login' ? (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[1] || (_cache[1] = [_createElementVNode("a", null, "版权所有：杭州我爱号码网电子商务有限公司", -1), _createElementVNode("a", null, "公司地址：浙江省杭州市拱墅区石桥路293号706室", -1), _createElementVNode("a", null, "联系电话：0571-28888520", -1), _createElementVNode("a", {
    target: "_blank",
    href: "http://www.beian.gov.cn"
  }, [_createElementVNode("img", {
    src: _imports_0
  }), _createElementVNode("span", null, "浙公网安备 33010502006969号")], -1), _createElementVNode("a", {
    href: "https://beian.miit.gov.cn",
    target: "_blank"
  }, "浙ICP备19034816号-2", -1)]))) : _createCommentVNode("", true)]);
}