import "core-js/modules/es.array.push.js";
import { Dialog } from 'vant';
export default {
  name: 'App',
  data() {
    return {
      customer: {},
      sendCodeDisable: false,
      sendCodeText: '发送短信',
      seconds: 60
    };
  },
  methods: {
    // 验证联系电话的合法性
    phoneValidator(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        return "请填写正确的手机号码";
      }
    },
    // 发送短信验证码
    sendCode() {
      let result = this.phoneValidator(this.customer.phone);
      if (result) {
        Dialog({
          message: result
        });
        return;
      }
      this.sendCodeDisable = true;
      let url = "/customer/send-code?phone=" + this.customer.phone;
      this.$axios.get(url).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          // 开始倒计时
          this.startCount();
        } else {
          Dialog.alert({
            message: resp.data.message
          });
          this.sendCodeDisable = false;
        }
      });
    },
    // 开启倒计时 60s
    startCount() {
      if (this.seconds > 0) {
        this.sendCodeText = this.seconds-- + "后再次发送";
        let _this = this;
        setTimeout(function () {
          _this.startCount();
        }, 1000);
      } else {
        this.sendCodeDisable = false;
        this.sendCodeText = "发送验证码";
        this.seconds = 60;
      }
    },
    login() {
      let url = '/customer/login';
      this.$axios.post(url, this.customer).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          sessionStorage.setItem("account", resp.data.data.account);
          sessionStorage.setItem("type", resp.data.data.type);
          sessionStorage.setItem("title", resp.data.data.name);
          document.title = "欢迎" + sessionStorage.getItem("title");
          this.$router.push("/");
        } else {
          Dialog({
            message: resp.data.message
          });
        }
      });
    }
  }
};