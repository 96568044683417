import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_BottomNav = _resolveComponent("BottomNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: "我的",
    "left-arrow": "",
    onClickLeft: _cache[0] || (_cache[0] = $event => $options.goBack())
  }), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "24"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_cell_group, {
        style: {
          "height": "100%",
          "min-height": "750px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell, {
          title: "账号信息",
          "is-link": "",
          to: "myinfo"
        }), _createVNode(_component_van_cell, {
          title: "我的推广链接",
          "is-link": "",
          to: "mylink"
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_BottomNav, {
    index: 3
  })]);
}