import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { Dialog } from 'vant';
import { Toast } from 'vant';
import BottomNav from '../components/BottomNav';
import provinceCities from '../assets/data/homeloc.json';
import rules from '../assets/data/rules.json';
export default {
  components: {
    BottomNav
  },
  data() {
    return {
      orderObj: {
        userInfo: {}
      },
      show: false,
      activeName: "",
      // 搜索类型 1-模糊搜索  2-按位搜索
      searchType: 1,
      // 查询按钮 状态
      disableQuery: false,
      // 模糊搜索关键字
      phoneKey: "",
      // 省市
      pro_cities: provinceCities,
      rules: rules,
      showOpt: false,
      showHomeLoc: false,
      showRules: false,
      showPrice: false,
      showChangePrice: false,
      //改价弹窗
      changePrice: {},
      //改价靓号对象
      phone: {},
      changePriceRange: "",
      //改价区间
      updObj: {},
      //改价对象-axios传入后端的参数

      opts: [{
        value: null,
        text: "运营商"
      }, {
        value: "2",
        text: "中国联通"
      }, {
        value: "3",
        text: "中国电信"
      }, {
        value: "1",
        text: "中国移动"
      }, {
        value: "4",
        text: "虚拟运营商"
      }],
      // 底价
      salePrices: ['全部', '<0元', '0元', '1元~100元', '101元~200元', '201元~500元', '501元~1000元', '1001元~2000元', '2001元~5000元', '5001元以上'],
      // 零售价
      costPrices: ['全部', '0元', '1元', '2元~100元', '101元~200元', '201元~500元', '501元~1000元', '1001元~2000元', '2001元~5000元', '5001元以上'],
      // 预存款
      advanceFees: ['全部', '0元', '1元~100元', '101元~200元', '201元~500元', '501元~1000元', '1001元~2000元', '2001元~5000元', '5001元以上'],
      homeLoc: "归属地",
      optName: "运营商",
      rule: '特征',
      price: '价格',
      // 查询条件
      queryObj: {},
      customerType: sessionStorage.getItem("type"),
      // 数量列表
      numberNums: [{
        value: null,
        text: "数量"
      }, {
        value: 7,
        text: "7个"
      }, {
        value: 6,
        text: "6个"
      }, {
        value: 5,
        text: "5个"
      }, {
        value: 4,
        text: "4个"
      }],
      // 数字列表
      numbers: [{
        value: null,
        text: "数字"
      }, {
        value: 9,
        text: "9"
      }, {
        value: 8,
        text: "8"
      }, {
        value: 7,
        text: "7"
      }, {
        value: 6,
        text: "6"
      }, {
        value: 5,
        text: "5"
      }, {
        value: 4,
        text: "4"
      }, {
        value: 3,
        text: "3"
      }, {
        value: 2,
        text: "2"
      }, {
        value: 1,
        text: "1"
      }],
      searchPhones: [],
      //搜索号码结果
      currentPage: 1,
      //当前页码
      loading: false,
      nums: [1, null, null, null, null, null, null, null, null, null, null],
      // 号码位数
      result: "",
      finished: false,
      focus: [true, false, false, false, false, false, false, false, false, false],
      excludeNum: [2, 3, 4, 5, 6, 7, 8, 9, 0],
      excludeNums: [false, false, false, false, false, false, false, false, false],
      moreNum: null
    };
  },
  methods: {
    openOrder(obj) {
      // 点击号码 打开预约窗口
      this.phoneObj = obj;
      this.show = true;
      this.orderObj.phoneNumber = obj.phoneNumber;
      this.orderObj.pOptId = obj.optId;
      this.orderObj.openSupplierId = obj.supplierId;
      this.orderObj.productId = obj.productId;
      this.orderObj.province = obj.province;
      this.orderObj.area = obj.area;
      this.orderObj.needPayPrice = obj.costPrice + obj.fee;
      this.orderObj.advanceFee = obj.fee;
      this.orderObj.lowConsume = obj.lowConsume;
      this.orderObj.onlineMonths = obj.onlineMonths;
      this.orderObj.firstMonthFeeType = obj.product.firstMonthFeeType;
      this.orderObj.configId = this.queryObj.configId;
      let deliverScope = obj.product.deliverScope;
      if (deliverScope == '省内') {
        // 省内发货的号码 下单地址限制在省内
        this.addressOptions = this.address.filter(item => {
          return this.orderObj.province.substring(0, 2) === item.text.substring(0, 2);
        });
      } else {
        this.addressOptions = this.address;
      }
    },
    // 验证改价靓号的合法性
    phoneValidator(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        return "请填写正确的改价靓号";
      }
    },
    nextFocus(item) {
      if (item == 1) {
        if (!/[3456789]$/.test(this.nums[item])) {
          this.nums[item] = "";
          return;
        }
      }
      if (item != 10 && /\d/.test(this.nums[item])) {
        // this.nums[item+1] = "";
        this.$refs['num' + (item + 1)][0].focus();
      }
    },
    // 确定运营商
    confirmOpt(value) {
      value = value.selectedOptions[0];
      this.optName = value.text;
      this.queryObj.optId = value.value;
      this.showOpt = false;
      this.currentPage = 1;
      this.loadData();
    },
    // 确定归属地
    confirmHomeLoc(value) {
      value = value.selectedOptions;
      console.log(value);
      let province = value[0].text;
      let area = value[1].text;
      if (province == '全国') {
        province = "";
        area = "";
      } else if (area == '全部') {
        area = "";
      }
      this.queryObj.province = province;
      this.queryObj.area = area;
      if (area != '') {
        this.homeLoc = area;
      } else if (province != '') {
        this.homeLoc = province;
      } else {
        this.homeLoc = '归属地';
      }
      this.currentPage = 1;
      this.showHomeLoc = false;
      this.loadData();
    },
    // 确定规律
    confirmRule(value) {
      value = value.selectedOptions;
      let pType = value[0].text;
      let aType = value[1].text;
      if (pType == '全部') {
        pType = "";
      }
      if (aType == '全部') {
        aType = "";
      }
      this.queryObj.pType = pType;
      this.queryObj.aType = aType;
      if (aType != '') {
        this.rule = aType;
      } else if (pType != '') {
        this.rule = pType;
      } else {
        this.rule = '特征';
      }
      this.currentPage = 1;
      this.showRules = false;
      this.loadData();
    },
    // 确定价格区间
    confirmPrice() {
      let salePrice = this.queryObj.salePrice;
      let costPrice = this.queryObj.costPrice;
      let advanceFee = this.queryObj.advanceFee;
      if (!salePrice && !costPrice && !advanceFee) {
        this.price = "价格";
      } else {
        this.price = "价格筛选...";
      }
      this.currentPage = 1;
      this.showPrice = false;
      this.loadData();
    },
    // 清除查询条件
    clearCondition() {
      this.excludeNums = [false, false, false, false, false, false, false, false, false];
      this.nums = [1, null, null, null, null, null, null, null, null, null, null];
      this.loadData(1);
      this.optName = "运营商";
      this.localName = "归属地";
      this.rule = "特征";
      this.price = "价格";
    },
    loadData(pageNum) {
      // 如果上一次还没加载完成，停止下一次加载
      if (this.loading) {
        return;
      }
      this.disableQuery = true;
      this.loading = true;
      this.result = "";
      let url = "/customer/main/phoneNumber/query";
      if (pageNum) {
        this.currentPage = pageNum;
      }
      if (this.currentPage == 1) {
        this.searchPhones = [];
      }
      this.queryObj.pageNum = this.currentPage;
      // 模糊搜索
      if (this.searchType == 1) {
        this.queryObj.phonePosition = null;
      } else {
        this.queryObj.phoneLike = null;
        let text = "";
        for (let i = 0; i < this.nums.length; i++) {
          if (this.nums[i]) {
            text += this.nums[i];
          } else {
            text += "_";
          }
        }
        this.queryObj.phonePosition = text;
      }
      // 不含数字
      this.queryObj.excludeNums = [];
      for (let i = 0; i < this.excludeNums.length; i++) {
        if (this.excludeNums[i]) {
          this.queryObj.excludeNums.push(this.excludeNum[i]);
        }
      }
      this.$axios.post(url, this.queryObj).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          let list = resp.data.list;
          if (list.length == 0) {
            if (this.currentPage == 1) {
              this.result = "没有符合您搜索条件的号码，换个条件试试";
            } else {
              this.result = "没有更多了，我是有底线的";
            }
            this.finished = true;
          } else {
            for (let i = 0; i < list.length; i++) {
              this.searchPhones.push(list[i]);
            }
            if (list.length < 40) {
              this.result = "没有更多了，我是有底线的";
              this.finished = true;
            } else {
              this.currentPage++;
            }
          }
        } else {
          Dialog({
            message: resp.data.message
          });
        }
        this.loading = false;
        this.disableQuery = false;
      });
    },
    addCart(phone) {
      let url = "/customer/main/cart/add";
      let params = {
        phoneNumber: phone.phoneNumber
      };
      this.$axios.post(url, params).then(resp => {
        if (resp.data.status == this.STATUS_SUCCESS) {
          Toast("加入购物车成功");
          // 更新购物车数量
          this.initCartNum();
        } else {
          Dialog({
            message: resp.data.message
          });
        }
      });
    },
    // 立即购买
    buy(phone) {
      this.$router.push({
        path: "/buy",
        query: {
          phoneNumber: phone.phoneNumber
        }
      });
    },
    openUrl(url) {
      window.location.href = url;
    },
    initCartNum() {
      // 查询购物车中添加的号码数量
      let url = "/customer/main/cart/my-cart-num";
      this.$axios.get(url).then(resp => {
        this.$emit("change-cartNum", resp.data.data);
      });
    },
    // 去后台改价
    onChangePrice() {
      this.updObj.phoneNumber = this.changePrice.phoneNumber;
      let url = "/customer/main/phoneNumber/upd-changePrice";
      this.$axios.post(url, this.updObj).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          Dialog({
            message: "改价成功"
          });
          //清空改价弹窗数据
          this.updObj = {};
          //关闭改价弹窗
          this.showChangePrice = false;
        } else {
          Dialog({
            message: resp.data.message
          });
        }
      });
    }
  },
  mounted: function () {

    // let url = "/customer/location";
    // this.$axios.get(url).then(resp => {
    //   let province = "浙江省";
    //   let city = "杭州市";
    //   if(resp.data.status == this.STATUS_SUCCESS){
    //     province = resp.data.data.province;
    //     city = resp.data.data.city;
    //   }
    //   a: for(let i=0;i<provinces.length;i++){
    //     if(province.indexOf(provinces[i].text) != -1){
    //       // 修改省份
    //       this.province = provinces[i].text;
    //       b: for(let i=0;i<pro_cities.length;i++){
    //         for(let j in pro_cities[i]){
    //           if(j == this.province){
    //             this.cities = pro_cities[i][j];
    //             this.cities.splice(0,0, {"text": "归属城市", "value": null});
    //             break b;
    //           }
    //         }
    //       }

    //       for(let j=0;j<this.cities.length;j++){
    //         if(city.indexOf(this.cities[j].text) != -1){
    //           this.city = this.cities[j].text;
    //           break a;
    //         }
    //       }
    //     }
    //   }
    //   this.optId = "2";
    //   this.selectSearch();
    // });

    // this.loadData();
    // 初始化购物车数量
    // this.initCartNum();
  }
};