import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "btn_div"
};
const _hoisted_3 = {
  class: "report-table"
};
const _hoisted_4 = ["name"];
const _hoisted_5 = {
  class: "my_order_item"
};
const _hoisted_6 = {
  class: "order_item_content"
};
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  key: 1
};
const _hoisted_10 = {
  key: 2
};
const _hoisted_11 = {
  key: 3
};
const _hoisted_12 = {
  class: "my_order_item"
};
const _hoisted_13 = {
  class: "order_item_content"
};
const _hoisted_14 = {
  class: "my_order_item"
};
const _hoisted_15 = {
  class: "order_item_content"
};
const _hoisted_16 = {
  class: "my_order_item"
};
const _hoisted_17 = {
  class: "order_item_content"
};
const _hoisted_18 = {
  class: "my_order_item"
};
const _hoisted_19 = {
  class: "order_item_content"
};
const _hoisted_20 = {
  class: "my_order_item"
};
const _hoisted_21 = {
  class: "order_item_content"
};
const _hoisted_22 = {
  class: "my_order_item"
};
const _hoisted_23 = {
  class: "order_item_content"
};
const _hoisted_24 = {
  class: "my_order_item"
};
const _hoisted_25 = {
  class: "order_item_content"
};
const _hoisted_26 = {
  key: 0,
  class: "red"
};
const _hoisted_27 = {
  key: 1,
  class: "green"
};
const _hoisted_28 = {
  key: 2,
  class: "blue"
};
const _hoisted_29 = {
  key: 3
};
const _hoisted_30 = {
  class: "my_order_item"
};
const _hoisted_31 = {
  class: "order_item_content red"
};
const _hoisted_32 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_picker = _resolveComponent("van-picker");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  const _component_van_date_picker = _resolveComponent("van-date-picker");
  const _component_van_list = _resolveComponent("van-list");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_BottomNav = _resolveComponent("BottomNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: "我的订单"
  }), _createVNode(_component_van_form, {
    onSubmit: _cache[18] || (_cache[18] = $event => $options.loadData(1)),
    style: {
      "margin-top": "0.05rem",
      "margin-bottom": "0.05rem"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell_group, {
      inset: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_van_field, {
        modelValue: $data.queryObj.phoneNumber,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.queryObj.phoneNumber = $event),
        name: "订购号码",
        label: "订购号码",
        type: "tel",
        placeholder: "请输入订购号码"
      }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
        modelValue: $data.queryObj.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.queryObj.name = $event),
        name: "客户姓名",
        label: "客户姓名",
        placeholder: "请输入客户姓名"
      }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
        modelValue: $data.queryObj.phone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.queryObj.phone = $event),
        name: "客户联系电话",
        label: "客户电话",
        type: "tel",
        placeholder: "请输入客户联系电话"
      }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
        modelValue: $data.statusText,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.statusText = $event),
        "is-link": "",
        readonly: "",
        name: "picker",
        label: "订单状态",
        placeholder: "点击选择订单状态",
        onClick: _cache[4] || (_cache[4] = $event => $data.showStatus = true)
      }, null, 8, ["modelValue"]), _createVNode(_component_van_popup, {
        show: $data.showStatus,
        "onUpdate:show": _cache[6] || (_cache[6] = $event => $data.showStatus = $event),
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          columns: $data.statuses,
          onConfirm: $options.confirmStatus,
          onCancel: _cache[5] || (_cache[5] = $event => $data.showStatus = false)
        }, null, 8, ["columns", "onConfirm"])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_field, {
        modelValue: $data.customerText,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.customerText = $event),
        "is-link": "",
        readonly: "",
        name: "picker",
        label: "订单来源",
        placeholder: "点击选择订单状态",
        onClick: _cache[8] || (_cache[8] = $event => $data.showCustomer = true)
      }, null, 8, ["modelValue"]), _createVNode(_component_van_popup, {
        show: $data.showCustomer,
        "onUpdate:show": _cache[10] || (_cache[10] = $event => $data.showCustomer = $event),
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          columns: $data.customers,
          onConfirm: $options.confirmCustomer,
          onCancel: _cache[9] || (_cache[9] = $event => $data.showCustomer = false),
          "columns-field-names": {
            text: 'name',
            value: 'id'
          }
        }, null, 8, ["columns", "onConfirm"])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_field, {
        modelValue: $data.queryObj.startDay,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => $data.queryObj.startDay = $event),
        "is-link": "",
        readonly: "",
        label: "起始日期",
        placeholder: "请选择起始日期",
        onClick: _cache[12] || (_cache[12] = $event => $data.showStartDay = true)
      }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
        modelValue: $data.queryObj.endDay,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $data.queryObj.endDay = $event),
        "is-link": "",
        readonly: "",
        label: "结束日期",
        placeholder: "请选择结束日期",
        onClick: _cache[14] || (_cache[14] = $event => $data.showEndDay = true)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
      plain: "",
      type: "primary",
      icon: "search",
      onClick: _cache[15] || (_cache[15] = $event => $options.loadData(1)),
      disabled: $data.btnDisabled
    }, {
      default: _withCtx(() => _cache[25] || (_cache[25] = [_createTextVNode("查询订单")])),
      _: 1
    }, 8, ["disabled"]), _createVNode(_component_van_button, {
      plain: "",
      type: "success",
      icon: "down",
      onClick: _cache[16] || (_cache[16] = $event => $options.exportOrder()),
      disabled: $data.btnDisabled
    }, {
      default: _withCtx(() => _cache[26] || (_cache[26] = [_createTextVNode("订单导出")])),
      _: 1
    }, 8, ["disabled"]), _createVNode(_component_van_button, {
      plain: "",
      type: "danger",
      icon: "bar-chart-o",
      onClick: _cache[17] || (_cache[17] = $event => $options.orderReport()),
      disabled: $data.btnDisabled
    }, {
      default: _withCtx(() => _cache[27] || (_cache[27] = [_createTextVNode("订单统计")])),
      _: 1
    }, 8, ["disabled"])])]),
    _: 1
  }), _createVNode(_component_van_popup, {
    show: $data.showReport,
    "onUpdate:show": _cache[19] || (_cache[19] = $event => $data.showReport = $event),
    position: "top",
    style: {
      height: '60%'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("table", _hoisted_3, [_cache[28] || (_cache[28] = _createElementVNode("tr", {
      align: "center"
    }, [_createElementVNode("td", {
      colspan: "3"
    }, "订单统计")], -1)), _cache[29] || (_cache[29] = _createElementVNode("tr", {
      class: "report-head"
    }, [_createElementVNode("td", null, "状态"), _createElementVNode("td", null, "数量"), _createElementVNode("td", null, "占比")], -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.reportItems, item => {
      return _openBlock(), _createElementBlock("tr", {
        key: 'tr_' + item.itemName
      }, [_createElementVNode("td", null, _toDisplayString(item.itemName), 1), _createElementVNode("td", null, _toDisplayString(item.num), 1), _createElementVNode("td", null, _toDisplayString(item.rate), 1)]);
    }), 128))])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showStartDay,
    "onUpdate:show": _cache[21] || (_cache[21] = $event => $data.showStartDay = $event),
    position: "bottom"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_date_picker, {
      modelValue: $data.startDay,
      "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => $data.startDay = $event),
      type: "date",
      title: "选择起始年月日",
      "min-date": $options.minDate,
      "max-date": $data.today,
      onConfirm: $options.confirmStartDay,
      onCancel: $options.cancelStartDay,
      "cancel-button-text": "清空"
    }, null, 8, ["modelValue", "min-date", "max-date", "onConfirm", "onCancel"])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showEndDay,
    "onUpdate:show": _cache[23] || (_cache[23] = $event => $data.showEndDay = $event),
    position: "bottom"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_date_picker, {
      modelValue: $data.endDay,
      "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => $data.endDay = $event),
      type: "date",
      title: "选择起始年月日",
      "min-date": $options.minDate,
      "max-date": $data.today,
      onConfirm: $options.confirmEndDay,
      onCancel: $options.cancelEndDay,
      "cancel-button-text": "清空"
    }, null, 8, ["modelValue", "min-date", "max-date", "onConfirm", "onCancel"])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_cell, null, {
    default: _withCtx(() => [_createVNode(_component_van_list, {
      style: {
        "margin-bottom": "1.56rem"
      },
      modelValue: $data.loading,
      "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => $data.loading = $event),
      finished: $data.finished,
      "finished-text": $data.result,
      onLoad: $options.loadData
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.myorders, order => {
        return _openBlock(), _createElementBlock("article", {
          key: order.id,
          name: order.id + '_' + order.type,
          class: "my_order_art"
        }, [_createElementVNode("div", _hoisted_5, [_cache[31] || (_cache[31] = _createElementVNode("label", {
          class: "order_item_title"
        }, "订购号码", -1)), _createElementVNode("span", _hoisted_6, [_createElementVNode("label", {
          innerHTML: order.phoneNumberDisplay,
          style: {
            "width": "1.8rem"
          }
        }, null, 8, _hoisted_7), _cache[30] || (_cache[30] = _createTextVNode("   ")), order.optId == 1 ? (_openBlock(), _createElementBlock("span", _hoisted_8, "移动")) : order.optId == 2 ? (_openBlock(), _createElementBlock("span", _hoisted_9, "联通")) : order.optId == 3 ? (_openBlock(), _createElementBlock("span", _hoisted_10, "电信")) : order.optId == 4 ? (_openBlock(), _createElementBlock("span", _hoisted_11, "虚商")) : _createCommentVNode("", true), _createTextVNode("   " + _toDisplayString(order.phoneProvince) + "   " + _toDisplayString(order.phoneArea), 1)])]), _createElementVNode("div", _hoisted_12, [_cache[32] || (_cache[32] = _createElementVNode("label", {
          class: "order_item_title"
        }, "订单编号", -1)), _createElementVNode("span", _hoisted_13, _toDisplayString(order.orderNo), 1)]), _createElementVNode("div", _hoisted_14, [_cache[33] || (_cache[33] = _createElementVNode("label", {
          class: "order_item_title"
        }, "下单时间", -1)), _createElementVNode("span", _hoisted_15, _toDisplayString(order.createTime), 1)]), _createElementVNode("div", _hoisted_16, [_cache[34] || (_cache[34] = _createElementVNode("label", {
          class: "order_item_title"
        }, "订单来源", -1)), _createElementVNode("span", _hoisted_17, _toDisplayString(order.customerName), 1)]), _createElementVNode("div", _hoisted_18, [_cache[35] || (_cache[35] = _createElementVNode("label", {
          class: "order_item_title"
        }, "开户套餐", -1)), _createElementVNode("span", _hoisted_19, _toDisplayString(order.productName), 1)]), _createElementVNode("div", _hoisted_20, [_cache[37] || (_cache[37] = _createElementVNode("label", {
          class: "order_item_title"
        }, "客户资料", -1)), _createElementVNode("span", _hoisted_21, [_createTextVNode(_toDisplayString(order.name) + "   " + _toDisplayString(order.phone) + "   " + _toDisplayString(order.idcard) + "  ", 1), _cache[36] || (_cache[36] = _createElementVNode("br", null, null, -1)), _createTextVNode(" " + _toDisplayString(order.province) + " " + _toDisplayString(order.city) + " " + _toDisplayString(order.area) + " " + _toDisplayString(order.address), 1)])]), _createElementVNode("div", _hoisted_22, [_cache[38] || (_cache[38] = _createElementVNode("label", {
          class: "order_item_title"
        }, "物流信息", -1)), _createElementVNode("span", _hoisted_23, _toDisplayString(order.expressCompany) + " " + _toDisplayString(order.expressNo), 1)]), _createElementVNode("div", _hoisted_24, [_cache[39] || (_cache[39] = _createElementVNode("label", {
          class: "order_item_title"
        }, "订单状态", -1)), _createElementVNode("span", _hoisted_25, [order.statusText == '退单' ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(order.statusText), 1)) : order.statusText == '激活' ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(order.statusText), 1)) : order.statusText == '已发货' ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(order.statusText), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(order.statusText), 1))])]), _createElementVNode("div", _hoisted_30, [_cache[40] || (_cache[40] = _createElementVNode("label", {
          class: "order_item_title"
        }, "订单备注", -1)), _createElementVNode("span", _hoisted_31, [_createTextVNode(_toDisplayString(order.remark) + " ", 1), order.reason != null && order.reason != '' ? (_openBlock(), _createElementBlock("span", _hoisted_32, "【" + _toDisplayString(order.reason) + "】", 1)) : _createCommentVNode("", true)])])], 8, _hoisted_4);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "finished", "finished-text", "onLoad"])]),
    _: 1
  }), _createVNode(_component_BottomNav, {
    index: 2
  })]);
}