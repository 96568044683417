import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  style: {
    "text-align": "center",
    "display": "flex",
    "align-items": "center",
    "justify-content": "center"
  }
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  class: "product_item"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "product_content"
};
const _hoisted_6 = {
  class: "title"
};
const _hoisted_7 = {
  class: "desc"
};
const _hoisted_8 = {
  class: "content"
};
const _hoisted_9 = {
  class: "value"
};
const _hoisted_10 = {
  class: "value"
};
const _hoisted_11 = {
  class: "value"
};
const _hoisted_12 = {
  class: "value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_button = _resolveComponent("van-button");
  const _component_BottomNav = _resolveComponent("BottomNav");
  return _openBlock(), _createElementBlock("div", null, [_cache[10] || (_cache[10] = _createElementVNode("div", {
    style: {
      "font-size": "0.2rem !important",
      "color": "red",
      "margin": "0.1rem auto"
    }
  }, [_createTextVNode(" 1、请勿直接转发该链接给客户。"), _createElementVNode("br"), _createTextVNode(" 2、以下产品打开后链接可以直接下单或者发给客户下单，下单后订单的发展来源归属到您的名下。"), _createElementVNode("br"), _createTextVNode(" 3、产品佣金政策找本公司商务咨询。"), _createElementVNode("br")], -1)), _createElementVNode("div", _hoisted_1, [_cache[1] || (_cache[1] = _createTextVNode(" 特惠套餐列表   ")), _createVNode(_component_van_button, {
    type: "primary",
    size: "small",
    onClick: $options.copyLink
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("复制推广链接")])),
    _: 1
  }, 8, ["onClick"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.products, (item, index) => {
    return _openBlock(), _createElementBlock("a", {
      class: "product_div",
      key: 'products_' + index,
      target: "_blank",
      href: item.h5Url + 'proxy/' + $data.source
    }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
      src: item.imageUrl
    }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(item.detail), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_9, _toDisplayString(item.commonFlow) + " GB", 1), _cache[2] || (_cache[2] = _createElementVNode("span", {
      class: "name"
    }, "通用流量", -1))]), _cache[6] || (_cache[6] = _createElementVNode("div", {
      class: "sep_v"
    }, null, -1)), _createElementVNode("div", null, [_createElementVNode("span", _hoisted_10, _toDisplayString(item.directFlow) + " GB", 1), _cache[3] || (_cache[3] = _createElementVNode("span", {
      class: "name"
    }, "定向流量", -1))]), _cache[7] || (_cache[7] = _createElementVNode("div", {
      class: "sep_v"
    }, null, -1)), _createElementVNode("div", null, [_createElementVNode("span", _hoisted_11, _toDisplayString(item.minutes) + " 分钟", 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
      class: "name"
    }, "语音通话", -1))]), _cache[8] || (_cache[8] = _createElementVNode("div", {
      class: "sep_v"
    }, null, -1)), _createElementVNode("div", null, [_createElementVNode("span", _hoisted_12, _toDisplayString(item.monthFee) + " 元", 1), _cache[5] || (_cache[5] = _createElementVNode("span", {
      class: "name"
    }, "月租", -1))])]), _cache[9] || (_cache[9] = _createElementVNode("div", {
      class: "btn_div"
    }, "立即办理", -1))])])], 8, _hoisted_2);
  }), 128)), _cache[11] || (_cache[11] = _createElementVNode("div", {
    style: {
      "height": "2rem"
    }
  }, null, -1)), _createVNode(_component_BottomNav, {
    index: 1
  })]);
}