import BottomNav from 'components/BottomNav';
export default {
  components: {
    BottomNav
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
  mounted: function () {}
};