import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { Dialog } from 'vant';
import XLSX from "xlsx";
import { Toast } from 'vant';
import BottomNav from '../components/BottomNav';
export default {
  components: {
    BottomNav
  },
  data() {
    return {
      result: "",
      finished: false,
      myorders: [],
      // 我的购物车中的商品
      currentPage: 1,
      // 当前页
      loading: false,
      activeName: "",
      queryObj: {
        pageNum: 1
      },
      active: 0,
      showStatus: false,
      showStartDay: false,
      showEndDay: false,
      statusText: '',
      today: new Date(),
      startDay: new Date(),
      endDay: new Date(),
      statuses: [{
        value: '-1',
        text: "全部"
      }, {
        value: '1',
        text: "待处理"
      }, {
        value: '2',
        text: "已下单"
      }, {
        value: '3',
        text: "已发货"
      }, {
        value: '4',
        text: "激活"
      }, {
        value: '5',
        text: "退单"
      }, {
        value: '6',
        text: "下单失败"
      }],
      btnDisabled: false,
      showCustomer: false,
      customers: [],
      customerText: '',
      showReport: false,
      reportItems: []
    };
  },
  computed: {
    // 最早日期是90天前
    minDate() {
      // 返回1970年1月1日到现在的时间毫秒数
      let time = new Date().getTime() - 3 * 30 * 24 * 60 * 60 * 1000;
      let a = new Date(time);
      return a;
    }
  },
  methods: {
    // 订单统计
    orderReport() {
      let url = "/customer/main/order/order-report";
      this.btnDisabled = true;
      this.$axios.post(url, this.queryObj).then(resp => {
        let list = resp.data.list;
        let total = 0;
        for (let i = 0; i < list.length; i++) {
          total += list[i].num;
        }
        this.reportItems = [];
        this.reportItems.push({
          itemName: '总订单量',
          num: total,
          rate: '100%'
        });
        if (total != 0) {
          for (let i = 0; i < list.length; i++) {
            this.reportItems.push({
              itemName: list[i].itemName,
              num: list[i].num,
              rate: Math.round(list[i].num * 10000 / total) / 100 + '%'
            });
          }
        }
        this.showReport = true;
      }).finally(() => {
        this.btnDisabled = false;
      });
    },
    // 订单导出
    exportOrder() {
      let url = "/customer/main/order/export-order";
      this.btnDisabled = true;
      this.$axios.post(url, this.queryObj).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          let data = [[]];
          let flag = true;
          resp.data.list.forEach(item => {
            let obj = [];
            for (let key in item) {
              if (flag) data[0].push(key);
              obj.push(item[key]);
            }
            data.push(obj);
            flag = false;
          });
          // 导出为Excel
          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, "order.xlsx");
        } else {
          Dialog.alert({
            message: '订单导出失败！'
          });
        }
      }).finally(() => {
        this.btnDisabled = false;
      });
    },
    loadData(pageNum) {
      // 如果上一次还没加载完成，停止下一次加载
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (pageNum) {
        this.currentPage = pageNum;
      }
      if (this.currentPage == 1) {
        this.myorders = [];
      }
      let url = "/customer/main/order/myorder";
      this.queryObj.pageNum = this.currentPage;
      this.$axios.post(url, this.queryObj).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          let list = resp.data.list;
          if (!list || list.length == 0) {
            if (this.currentPage == 1) {
              this.result = "您没有符合条件的订单";
            } else {
              this.result = '仅展示近90天内的订单，更早订单请咨询客服';
            }
            this.finished = true;
          } else {
            for (let i = 0; i < list.length; i++) {
              this.myorders.push(list[i]);
            }
            if (list.length < 40) {
              this.result = '仅展示近90天内的订单，更早订单请咨询客服';
              this.finished = true;
            } else {
              this.currentPage++;
            }
            this.loading = false;
          }
        } else {
          this.finished = true;
          Toast(resp.data.message);
        }
        this.loading = false;
      });
    },
    confirmStatus(val) {
      if (val.value == -1) {
        this.statusText = '';
      } else {
        this.statusText = val.text;
      }
      this.queryObj.status = val.value;
      this.showStatus = false;
      this.loadData(1);
    },
    confirmCustomer(val) {
      if (val.id == -1) {
        this.customerText = '';
        this.queryObj.customerId = null;
      } else {
        this.customerText = val.name;
        this.queryObj.customerId = val.id;
      }
      this.showCustomer = false;
      this.loadData(1);
    },
    // 确认起始日期
    confirmStartDay(val) {
      this.queryObj.startDay = this.formatDate(val);
      this.showStartDay = false;
      this.loadData(1);
    },
    // 确认截止日期
    confirmEndDay(val) {
      this.queryObj.endDay = this.formatDate(val);
      this.showEndDay = false;
      this.loadData(1);
    },
    // 清空起始日期
    cancelStartDay() {
      this.queryObj.startDay = null;
      this.showStartDay = false;
      this.loadData(1);
    },
    // 清空截止日期
    cancelEndDay() {
      this.queryObj.endDay = null;
      this.showEndDay = false;
      this.loadData(1);
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return year + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);
    },
    stopOpen: function (e) {
      e.stopPropagation();
    }
  },
  mounted: function () {
    // // 查询购物车中添加的号码数量
    // let url = "/customer/main/cart/my-cart-num";
    // this.$axios.get(url).then(resp => {
    //   this.$emit("change-cartNum", resp.data.data);
    //   // this.loadData();
    // });

    this.loadData(1);
    let url = '/customer/main/order/my-customers';
    this.$axios.post(url).then(resp => {
      this.customers = resp.data.list;
      this.customers.unshift({
        id: -1,
        name: '全部'
      });
    });
  }
};