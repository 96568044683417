import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_BottomNav = _resolveComponent("BottomNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: "账号信息",
    "left-arrow": "",
    onClickLeft: _cache[0] || (_cache[0] = $event => $options.goBack())
  }), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "24"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_cell_group, {
        style: {
          "height": "100%",
          "min-height": "750px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell, {
          title: "名字",
          value: _ctx.customer.name
        }, null, 8, ["value"]), _ctx.customer.type == 2 ? (_openBlock(), _createBlock(_component_van_cell, {
          key: 0,
          title: "店铺编号",
          value: _ctx.customer.shopNo
        }, null, 8, ["value"])) : _createCommentVNode("", true), _createVNode(_component_van_cell, {
          title: "绑定手机",
          value: _ctx.customer.phone
        }, null, 8, ["value"]), _createVNode(_component_van_cell, {
          title: "联系人",
          value: _ctx.customer.contactName
        }, null, 8, ["value"]), _ctx.customer.type == 2 ? (_openBlock(), _createBlock(_component_van_cell, {
          key: 1,
          title: "店铺位置",
          value: _ctx.customer.province + ' ' + _ctx.customer.city + ' ' + _ctx.customer.district + ' ' + _ctx.customer.address
        }, null, 8, ["value"])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_BottomNav, {
    index: 3
  })]);
}