import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/yikh_logo.png';
const _hoisted_1 = {
  id: "app"
};
const _hoisted_2 = {
  class: "loginCol"
};
const _hoisted_3 = {
  style: {
    "margin": "16px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  const _component_van_row = _resolveComponent("van-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_row, {
    class: "content"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "24",
      style: {
        "text-align": "left",
        "display": "flex",
        "align-items": "center"
      }
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        class: "logo"
      }, null, -1), _createTextVNode("     亿卡汇订单管理系统 ")])),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "24"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_cache[5] || (_cache[5] = _createElementVNode("h3", null, "登 录", -1)), _createVNode(_component_van_form, {
        onSubmit: $options.login
      }, {
        default: _withCtx(() => [_createVNode(_component_van_field, {
          modelValue: $data.customer.phone,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.customer.phone = $event),
          modelModifiers: {
            trim: true
          },
          placeholder: "请输入手机号码",
          rules: [{
            required: true,
            message: '请填写手机号码',
            validator: $options.phoneValidator
          }]
        }, null, 8, ["modelValue", "rules"]), _createVNode(_component_van_field, {
          modelValue: $data.customer.password,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.customer.password = $event),
          center: "",
          clearable: "",
          placeholder: "请输入短信验证码",
          rules: [{
            required: true,
            message: '请填写短信验证码'
          }]
        }, {
          button: _withCtx(() => [_createVNode(_component_van_button, {
            size: "small",
            type: "primary",
            disabled: $data.sendCodeDisable,
            onClick: _cache[1] || (_cache[1] = $event => $options.sendCode())
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString($data.sendCodeText), 1)]),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "info",
          "native-type": "submit"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" 登 录 ")])),
          _: 1
        })])]),
        _: 1
      }, 8, ["onSubmit"])])]),
      _: 1
    })]),
    _: 1
  })]);
}