import BottomNav from 'components/BottomNav';
import VueClipboard from "vue-clipboard3";
import { showToast } from 'vant';
const {
  toClipboard
} = VueClipboard();
export default {
  components: {
    BottomNav
  },
  data() {
    return {
      customer: {},
      link: ''
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    myinfo() {
      let url = '/customer/main/myinfo';
      this.$axios.post(url).then(resp => {
        this.customer = resp.data.data;
        this.link = 'https://m.52haoma.com/ad-web/proxy/' + this.customer.account;
      });
    },
    copyTelcomStar59() {
      // const  {clipboard} = VueClipboard();
      this.copy(this.link);
      showToast("推广链接复制成功");
    },
    copy(Msg) {
      try {
        //复制
        toClipboard(Msg);

        //下面可以设置复制成功的提示框等操作
        //...
      } catch (e) {
        //复制失败
        console.error(e);
      }
    }
  },
  mounted: function () {
    this.myinfo();
  }
};