import { Dialog } from 'vant';
import { Toast } from 'vant';
import area from '../assets/js/area.js';
import frontImg from '../assets/idcard1.jpg';
import backImg from '../assets/idcard2.jpg';
import nowrapImg from '../assets/idcard3.jpg';
import idcard from '../assets/js/idcard.js';
import BottomNav from '../components/BottomNav';
export default {
  components: {
    BottomNav
  },
  data() {
    return {
      order: {},
      info: {},
      orderId: null,
      areaList: area,
      value: null,
      showArea: false,
      frontSrc: frontImg,
      backSrc: backImg,
      nowrapSrc: nowrapImg,
      // 上传的照片对象
      uploadForm: new FormData(),
      photoList: [null, null, null],
      leftTime: "15:00",
      title: "订单",
      phonePattern: /^1[3-9]\d{9}$/
    };
  },
  methods: {
    checkIdcard(val) {
      return idcard(val);
    },
    loadData() {
      let url = '/customer/main/order/query';
      let params = {
        order: this.orderId
      };
      this.$axios.post(url, params).then(resp => {
        if (resp.data.status == this.STATUS_SUCCESS) {
          this.order = resp.data.data;
          if (this.order != null) {
            this.title = "号码" + this.order.order.phoneNumber + "订单";
            // 未支付
            if (this.order.order.status == 20) {
              // 开启倒计时模式
              this.startReserveTime();
              //  查询支付宝支付结果。。。。。。。。
              let vue = this;
              setTimeout(function () {
                vue.queryPayResult();
              }, 2000);
            }
          }
        } else {
          Dialog({
            message: resp.data.message
          });
        }
      });
    },
    onConfirm(values) {
      this.info.province = values[0].name;
      this.info.city = values[1].name;
      this.info.area = values[2].name;
      this.value = values[0].name + "/" + values[1].name + "/" + values[2].name;
      this.showArea = false;
    },
    submitInfo() {
      Dialog.confirm({
        message: "确定要提交资料吗？"
      }).then(() => {
        this.info.order = this.orderId;
        let url = '/customer/main/order/submit-order-info';
        this.$axios.post(url, this.info).then(resp => {
          if (resp.data.status == this.STATUS_SUCCESS) {
            Toast("资料提交成功");
          } else {
            Dialog({
              message: resp.data.message
            });
          }
          setTimeout(function () {
            window.location.reload(); // 刷新页面
          }, 1000);
        });
      });
    },
    readFront(file) {
      // 读取正面照片
      this.frontSrc = file.content;
      this.photoList[0] = file.file;
    },
    readBack(file) {
      // 读取背面照片
      this.backSrc = file.content;
      this.photoList[1] = file.file;
    },
    readNowrap(file) {
      // 读取免冠照片
      this.nowrapSrc = file.content;
      this.photoList[2] = file.file;
    },
    // 点击完成订单按钮
    doneOrder() {
      if (this.photoList[0] == null) {
        Toast("请上传身份证正面照片");
        return;
      }
      if (this.photoList[1] == null) {
        Toast("请上传身份证背面照片");
        return;
      }
      if (this.photoList[2] == null) {
        Toast("请上传正面免冠照片");
        return;
      }
      Dialog.confirm({
        message: "确定要上传照片完成订单吗？"
      }).then(() => {
        for (let i = 0; i < this.photoList.length; i++) {
          this.uploadForm.append("photos", this.photoList[i]);
        }
        this.uploadForm.append("order", this.orderId);
        let url = '/customer/main/order/submit-photos';
        this.$axios.post(url, this.uploadForm).then(resp => {
          if (resp.data.status == this.STATUS_SUCCESS) {
            Toast("订单已经创建完成！");
          } else {
            Dialog({
              message: resp.data.message
            });
          }
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        });
      });
    },
    // 倒计时
    startReserveTime() {
      let leftTime = this.order.leftTime;
      // 剩余分钟
      let minutes = parseInt(leftTime / 1000 / 60);
      // 剩余秒数
      let seconds = parseInt((leftTime - minutes * 60 * 1000) / 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.leftTime = minutes + ":" + seconds;
      this.order.leftTime -= 1000;
      if (this.order.leftTime < 0) {
        // 请求服务端要求关闭订单
        let url = '/customer/main/order/cancel-order';
        let params = {
          order: this.orderId
        };
        this.$axios.post(url, params).then(resp => {
          if (resp.data.status == this.STATUS_SUCCESS) {
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            Dialog({
              message: resp.data.message
            });
          }
        });
      } else {
        let vue = this;
        // 继续计时
        setTimeout(function () {
          vue.startReserveTime();
        }, 1000);
      }
    },
    queryPayResult() {
      let url = '/customer/main/order/query-alipay-result';
      let params = {
        order: this.orderId
      };
      this.$axios.post(url, params).then(resp => {
        if (resp.data.status == this.STATUS_SUCCESS) {
          if (resp.data.data) {
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            let vue = this;
            // 每隔3s查询支付结果
            setTimeout(function () {
              vue.queryPayResult();
            }, 3000);
          }
        } else {
          Dialog({
            message: resp.data.message
          });
        }
      });
    }
  },
  mounted: function () {
    this.orderId = encodeURIComponent(this.$route.query.order);
    this.loadData();
  }
};