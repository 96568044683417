import { Toast } from 'vant';
import { Dialog } from 'vant';
import BottomNav from 'components/BottomNav';
export default {
  components: {
    BottomNav
  },
  data: () => ({
    customer: {}
  }),
  methods: {
    checkPwd() {
      let pwd = this.customer.newPassword;
      if (!pwd) {
        return false;
      }
      let reg = /^[\da-zA-Z~!@#$%^&*()]{6,18}$/;
      if (!reg.test(pwd)) {
        return false;
      }
      return true;
    },
    checkPwd2() {
      let pwd = this.customer.newPassword;
      let pwd2 = this.customer.nowPassword2;
      if (!pwd2) {
        return false;
      }
      if (pwd2 != pwd) {
        return false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    myinfo() {
      let url = '/customer/main/myinfo';
      this.$axios.post(url).then(resp => {
        if (resp.data.status == this.STATUS_SUCCESS) {
          this.customer = resp.data.data;
        } else {
          Toast(resp.data.message);
        }
      });
    },
    updatePwd() {
      let url = '/customer/main/update-pwd';
      Dialog.confirm({
        message: "确定要修改密码吗？"
      }).then(() => {
        this.$axios.post(url, this.customer).then(resp => {
          if (resp.data.status == this.STATUS_SUCCESS) {
            window.location.reload();
            Toast("修改成功");
          } else {
            Toast(resp.data.message);
          }
        });
      });
    }
  },
  mounted: function () {
    this.myinfo();
  }
};