import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "position": "fixed",
    "bottom": "0",
    "width": "7.5rem",
    "height": "1.56rem",
    "background": "#ffffff",
    "z-index": "99"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_grid_item = _resolveComponent("van-grid-item");
  const _component_van_grid = _resolveComponent("van-grid");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("section", _hoisted_1, [_createVNode(_component_van_grid, {
    "column-num": 4
  }, {
    default: _withCtx(() => [_createVNode(_component_van_grid_item, {
      style: _normalizeStyle($props.index == 0 ? 'color: red' : 'color: black'),
      icon: "wap-home",
      text: "靓号",
      to: "/"
    }, null, 8, ["style"]), _createVNode(_component_van_grid_item, {
      style: _normalizeStyle($props.index == 1 ? 'color: red' : 'color: black'),
      icon: "cart",
      text: "特惠套餐",
      to: "/product-list"
    }, null, 8, ["style"]), _createVNode(_component_van_grid_item, {
      style: _normalizeStyle($props.index == 2 ? 'color: red' : 'color: black'),
      icon: "bag",
      text: "订单",
      to: "/myorder"
    }, null, 8, ["style"]), _createVNode(_component_van_grid_item, {
      style: _normalizeStyle($props.index == 3 ? 'color: red' : 'color: black'),
      icon: "friends",
      text: "我",
      to: "/me"
    }, null, 8, ["style"])]),
    _: 1
  })])]);
}