import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "coupon_price"
};
const _hoisted_3 = {
  class: "coupon_desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  const _component_van_list = _resolveComponent("van-list");
  const _component_van_row = _resolveComponent("van-row");
  const _component_BottomNav = _resolveComponent("BottomNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_nav_bar, {
      title: "我的优惠券",
      "left-arrow": "",
      onClickLeft: _cache[0] || (_cache[0] = $event => $options.goBack())
    }), _createVNode(_component_van_tabbar, {
      modelValue: _ctx.coupon.status,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.coupon.status = $event),
      fixed: false,
      onChange: $options.chgStatus
    }, {
      default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
        icon: "balance-list-o",
        name: 0
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("可用")])),
        _: 1
      }), _createVNode(_component_van_tabbar_item, {
        icon: "completed",
        name: 1
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("已使用")])),
        _: 1
      }), _createVNode(_component_van_tabbar_item, {
        icon: "failure",
        name: -1
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("已过期")])),
        _: 1
      })]),
      _: 1
    }, 8, ["modelValue", "onChange"]), _createVNode(_component_van_list, {
      style: {
        "margin-bottom": "76px"
      },
      modelValue: _ctx.loading,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.loading = $event),
      finished: _ctx.finished,
      "finished-text": _ctx.result,
      onLoad: $options.mycoupon
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coupons, item => {
        return _openBlock(), _createElementBlock("div", {
          key: 'coupon_' + item.id
        }, [_createElementVNode("div", {
          class: _normalizeClass(item.status == 0 ? 'coupon' : 'coupon_use')
        }, [_createElementVNode("section", _hoisted_2, " ￥" + _toDisplayString(item.discountAmount), 1), _createElementVNode("section", _hoisted_3, [_createElementVNode("h4", null, _toDisplayString(item.name), 1), _createElementVNode("span", null, "有效期：" + _toDisplayString(item.liveDay) + "~" + _toDisplayString(item.deadDay), 1), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)), _createElementVNode("span", null, "使用说明：优惠券只能抵扣服务费部分，服务费满" + _toDisplayString(item.meetAmount) + "使用，一单仅限使用一张。仅限在有效期内使用，过期作废。", 1)])], 2)]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "finished", "finished-text", "onLoad"])]),
    _: 1
  }), _createVNode(_component_BottomNav, {
    onChangeCartNum: _cache[3] || (_cache[3] = $event => _ctx.cartNum = $event)
  })]);
}