import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  style: {
    "margin": "16px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_BottomNav = _resolveComponent("BottomNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_nav_bar, {
      title: "修改密码",
      "left-arrow": "",
      onClickLeft: _cache[0] || (_cache[0] = $event => $options.goBack())
    }), _createVNode(_component_van_col, {
      span: "24",
      style: {
        "padding": "10px",
        "text-align": "left",
        "font-size": "14px"
      }
    }, {
      default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("span", {
        class: "pwdnote"
      }, "温馨提示：密码必须由6-18位的数字、字母、特殊符号（% $ % # 等）组成。请不要轻易将密码泄露给别人！", -1)), _createVNode(_component_van_form, {
        onSubmit: $options.updatePwd
      }, {
        default: _withCtx(() => [_createVNode(_component_van_field, {
          modelValue: _ctx.customer.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.customer.password = $event),
          modelModifiers: {
            trim: true
          },
          type: "password",
          name: "旧密码",
          label: "旧密码",
          placeholder: "请输入旧密码",
          rules: [{
            required: true
          }]
        }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
          modelValue: _ctx.customer.newPassword,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.customer.newPassword = $event),
          modelModifiers: {
            trim: true
          },
          type: "password",
          name: "新密码",
          label: "新密码",
          placeholder: "请输入新密码",
          rules: [{
            validator: $options.checkPwd,
            message: '密码由6-18位的数字、字母、特殊符号组成'
          }]
        }, null, 8, ["modelValue", "rules"]), _createVNode(_component_van_field, {
          modelValue: _ctx.customer.nowPassword2,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.customer.nowPassword2 = $event),
          modelModifiers: {
            trim: true
          },
          type: "password",
          name: "确认密码",
          label: "确认密码",
          placeholder: "请输入确认密码",
          rules: [{
            validator: $options.checkPwd2,
            message: '确认密码不能为空并且必须和新密码一致'
          }]
        }, null, 8, ["modelValue", "rules"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "warning",
          "native-type": "submit"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" 确 定 修 改 密 码 ")])),
          _: 1
        })])]),
        _: 1
      }, 8, ["onSubmit"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_BottomNav, {
    onChangeCartNum: _cache[4] || (_cache[4] = $event => _ctx.cartNum = $event)
  })]);
}